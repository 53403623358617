import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _ from 'lodash';
import { graphql } from 'gatsby';

import Hero from '../components/Hero';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';

export const ArticleTemplate = ({
	content,
	contentComponent,
	description,
	title,
	helmet,
	files = [],
}) => {
	const PostContent = contentComponent || Content;
	return (
		<>
			{helmet || ''}
			<Hero title={title} />
			<section className="section">
				<div className="container">
					<div className="columns">
						<div className="column is-10 is-offset-1">
							<h1 className="title is-size-2 has-text-weight-bold is-bold-light">
								{title}
							</h1>
							<p className="has-text-weight-semibold">{description}</p>
							<PostContent content={content} />
							<ListFiles files={files} />
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

const ListFiles = ({ files }) => {
	if (_.isEmpty(files)) return null;

	return (
		<>
			{files
				.filter(({ title }) => title !== 'dummy')
				.map(({ title, file }) => (
					<p>
						<a
							href={file.publicURL}
							target="_blank"
							rel="noreferrer"
							className="link"
						>
							{title}
						</a>
					</p>
				))}
		</>
	);
};

ArticleTemplate.propTypes = {
	content: PropTypes.node.isRequired,
	contentComponent: PropTypes.func,
	description: PropTypes.string,
	title: PropTypes.string,
	helmet: PropTypes.object,
	files: PropTypes.array,
};

const BlogPost = ({ data }) => {
	const { frontmatter, html } = data.markdownRemark;

	return (
		<Layout>
			<ArticleTemplate
				content={html}
				contentComponent={HTMLContent}
				description={frontmatter?.description}
				helmet={
					<Helmet>
						<title>{`${frontmatter?.title}`}</title>
						<meta name="description" content={`${frontmatter?.description}`} />
					</Helmet>
				}
				title={frontmatter?.title}
				files={frontmatter?.files}
			/>
		</Layout>
	);
};

BlogPost.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.object,
	}),
};

export default BlogPost;

export const pageQuery = graphql`
	query BlogPostByID($id: String!) {
		markdownRemark(id: { eq: $id }) {
			id
			html
			frontmatter {
				date(formatString: "DD MMMM, YYYY", locale: "sv")
				title
				description
				files {
					title
					file {
						base
						id
						publicURL
					}
				}
			}
		}
	}
`;
